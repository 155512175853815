import { NavLink } from 'react-router-dom';
import { Navigation } from '../Navigation/Navigation';
import './Header.css'
import { BASE_URL } from '../Navigation/constants';

export const Header = () => {
  return (
    <header className="Header">
      <NavLink to={BASE_URL} className="Header-Name">Maggie Cody</NavLink>
      <Navigation />
    </header>
  )
};