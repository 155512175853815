import { Gradient } from '../Gradient/Gradient';
import './Home.css';

export const Home = () => (
  <>
    <div className="Home">
      <div className="Home-Wrapper">
        <div>
          <h1>Maggie Cody</h1>
          <p className="Home-Subtitle"><i>CREATIVE</i> SOFTWARE ENGINEER</p>
        </div>
        <div className="Home-Footnote">
          <p>
            I’m passionate about creating <i>memorable</i> user 
            experiences and making the web <i>accessible to all</i>.
          </p>
        </div>
      </div>
      <Gradient />
    </div>
  </>
);