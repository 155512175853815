import { About, Header, Home, Resume } from './components';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <HashRouter>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route
              path="/"
              element={<Home />}
            />
            <Route
              path="about"
              element={<About />}
            />
            <Route
              path="resume"
              element={<Resume />}
            />
            {/*
              <Route
                path="*"
                element={<Error err={404} />}
              />
            */}
          </Routes>
        </main>
      </div>
    </HashRouter>
  );
}

export default App;
