import { NavLink, useLocation } from 'react-router-dom';
import { BASE_URL, HOME, NAV_ITEMS } from './constants';
import './Navigation.css';

type Props = {
  name: string;
}

const NavItem = ({ name }: Props) => {
  const location = useLocation();

  const isCurrent = name === HOME
    ? location.pathname === '/'
    : location.pathname.includes(name.toLowerCase())

  return (
    <li>
      <NavLink
        to={name === HOME ? BASE_URL : `${BASE_URL}/${name.toLowerCase()}`}
        aria-current={isCurrent ? 'page' : undefined}
      >
        <span>{name}</span>
      </NavLink>
    </li>
  );
};

export const Navigation = () => {
  return (
    <nav className="Navigation">
      <ul>
        {NAV_ITEMS.map((name, index) => 
          <NavItem key={index} name={name} />
        )}
      </ul>
    </nav>
  )
};