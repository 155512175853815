import './Resume.css';
import { RESUME_EDUCATION, RESUME_EXPERIENCE, RESUME_SKILLS } from './constants';

type RatingProps = {
  rating: number;
};

const Rating = ({ rating }: RatingProps) => {
  const filledCircles = Array.from({ length: rating }, (_, index) => (
    <span key={index} className="Rating-Circle Rating-Circle-Filled"></span>
  ));

  const outlinedCircles = Array.from({ length: 5 - rating }, (_, index) => (
    <span key={index} className="Rating-Circle"></span>
  ));

  return (
    <div className="Rating">
      {filledCircles}
      {outlinedCircles}
    </div>
  );
};

type ListItemProps = {
  title: string;
  dates?: string;
  rating?: number;
};

const ListItem = ({title, dates, rating}: ListItemProps) => {
  return (
    <li>
      <span>{title}</span>
      {dates &&
        <span className="Resume-Dates">{dates}</span>
      }
      {rating &&
        <Rating rating={rating} />
      }
    </li>
  );
}

export const Resume = () => {
  return (
    <>
      <h1 className="Resume-Heading">Maggie Cody</h1>
      <div className="Resume">
        <div className="Resume-Column">
          <h2>EXPERIENCE</h2>
          <ul>
            {RESUME_EXPERIENCE.map((item, index) =>
              <ListItem key={index} {...item} />
            )}
          </ul>
          <br />
          <h2>EDUCTAION</h2>
          <ul>
            {RESUME_EDUCATION.map((item, index) =>
              <ListItem key={index} {...item} />
            )}
          </ul>
        </div>
        <div className="Resume-Column">
          <h2>SKILLS</h2>
          <ul>
            {RESUME_SKILLS.map((item, index) =>
              <ListItem key={index} {...item} />
            )}
          </ul>
        </div>
      </div>
      {/* TODO: Add link to download */ }
    </>
  )
};