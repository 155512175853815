
import { ReactComponent as BehanceIcon } from '../../assets/icons/behance.svg';
import { ReactComponent as GitHubIcon } from '../../assets/icons/github.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icons/linkedin.svg';
import './SocialLinks.css'

export const SocialLinks = () => (
  <div className="SocialLinks">
    <a
      href="https://www.linkedin.com/in/marguerite-cody/"
      target="_blank"
      rel="noreferrer"
    >
      <LinkedInIcon aria-label="LinkedIn" />
    </a>
    <a
      href="https://github.com/maggievcody"
      target="_blank"
      rel="noreferrer"
    >
      <GitHubIcon aria-label="Github" />
    </a>
    <a
      href="https://www.instagram.com/maggie_cody"
      target="_blank"
      rel="noreferrer"
    >
      <InstagramIcon aria-label="Instagram" />
    </a>
    <a
      href="https://www.behance.net/codyx089b1c6"
      target="_blank"
      rel="noreferrer"
    >
      <BehanceIcon aria-label="Behance" />
    </a>
  </div>
);